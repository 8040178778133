import { homeConstants } from "constants/home/home.constants";

const initialState = {
  place: [],
};

export function home(state = initialState, action: any) {
  switch (action.type) {
    case homeConstants.SET_PLACE_IMAGE_DETAIL: // place's image
      return {
        ...state,
        place: action.placeDetail,
      };

    default:
      return state;
  }
}
