import { expressOrderConstants } from "constants/expressOrder/expressOrder.constants";

const initialState = {
    encryptedUrlDetail: null,
    placeDetail: null,
    orderDetail: null,
    generatedAuthToken: null,
};

export function expressOrder(state = initialState, action: any) {
    switch (action.type) {
        case expressOrderConstants.GET_PLACE_DETAIL: // get place detail by placeid
            return {
                ...state,
                placeDetail: action.placeDetail
            }
        case expressOrderConstants.GET_ENCRYPTED_URL_VALUE: // get encrypted url value
            return{
                ...state,
                encryptedUrlDetail: action.urlValue
            }
        case expressOrderConstants.CREATE_ORDER: // create order
            return{
                ...state,
                orderDetail: action.orderDetail
            }
        case expressOrderConstants.SET_AUTH_TOKEN: // generate token
            return{
                ...state,
                generatedAuthToken: action.generatedToken
            }
        default:
            return state
    }
}