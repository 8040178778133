import { authHeader, captchaAuthHeader, captchaHeader, header } from "helpers/header";
import { handleResponse } from "helpers/handleResponse";
import { store } from "../../store/store";

const apiurl = process.env.REACT_APP_API_URL;

export const loginServices = {
  getEmailVerificationDetail,
  getGenerateOtpDetail,
  getVerifiedOtpDetail,
  getUserDetail,
  getUserDetailsOfEautoReacue,
  savePrimaryEmail
};

// check user is exist or not from email
function getEmailVerificationDetail(
  code: any,
  mobileNo: any,
  isPhoneExists: boolean
) {
  const requestOption = {
    method: "POST",
    headers: header(),
    body: JSON.stringify({
      email: mobileNo.email,
      phone: mobileNo.number,
      name: mobileNo.fName ? mobileNo.fName + " " + mobileNo.lName : null,
      isPhoneExists: isPhoneExists,
    }),
  };

  return fetch(apiurl + "api/validate-user-portal", requestOption)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: null,
        error: error?.detail || "Failed to fetch",
      };
    });

  // return fetch(apiurl + "api/validate-user", requestOption)
  //   .then(handleResponse)
  //   .then((data) => {
  //     return data;
  //   })
  //   .catch((error) => {
  //     return {
  //       status: error?.status,
  //       data: null,
  //       error: error?.detail || "Failed to fetch",
  //     };
    // });
}

// generate otp
function getGenerateOtpDetail(code: any, mobileNo: any, email?: string, newEmail?: string) {
  let enableCaptcha = store.getState().passes?.generalSettingDetail?.data?.EnableCaptcha;
  enableCaptcha = enableCaptcha?.toLowerCase() === "true" ? true : false;
  const requestOption = {
    method: "POST",
    headers: newEmail ? enableCaptcha ? captchaAuthHeader() : authHeader() : enableCaptcha ? captchaHeader() : header(),
    body: JSON.stringify({
      email: newEmail ? newEmail : mobileNo.email ? mobileNo.email : email,
      phone: mobileNo.number,
      app: 3,
      option: 1,
      data: "non voluptate fugiat ullamco",
    }),
  };

  return fetch(apiurl + "api/otp", requestOption)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
}

// verify otp
function getVerifiedOtpDetail(code: any, mobileNo: any, oldPhone? : string, oldName?: string) {
  const requestOption = {
    method: "POST",
    headers: mobileNo.addEmail ? authHeader() : header(),
    body: JSON.stringify({
      email: mobileNo.email,
      phone:  mobileNo.number,
      app: 3,
      appDeviceOs: 2,
      otpCode: mobileNo.otpNumber,
      appDeviceUniqueId: "webapp",
      name: mobileNo.fName ? mobileNo.fName + " " + mobileNo.lName : oldName,
      addEmail: mobileNo.addEmail ? true : false,
      setPrimaryEmail: mobileNo.isPrimaryEmail ? true : false
    }),
  };

  return fetch(apiurl + "api/otp-login", requestOption)
    .then(handleResponse)
    .then((data) => {
      if (data === "SMS Code does not match or has expired") {
        return "SMS Code does not match or has expired";
      } 
      else if(data === "Magic Link does not match or has expired"){
        return "Magic Link does not match or has expired";
      }
      else {
        if(data.data != null){
          localStorage.setItem("BearerToken", JSON.stringify(data.data.id_token));
          localStorage.setItem("userDetails", JSON.stringify(data.data.user));
          let tempData = {
            "userDetail": data.data.user,
            "token": data.data.id_token
          }
          localStorage.setItem("OfflineUserDetail", JSON.stringify(tempData));
          localStorage.setItem("IsUserReloaded", "true");
        }
        return data;
      }
    })
    .catch((error) => {
      debugger;
      return {
        status: 409,
        data: {},
        error: error,
      };
    });
}

// get user detail
function getUserDetail() {
  const requestOption = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiurl + "api/user", requestOption)
    .then(handleResponse)
    .then((data) => {
      localStorage.setItem("userDetails", JSON.stringify(data.data));
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

function getVerifiedOtpDetailProfile(code: any, mobileNo: any, oldPhone? : string, oldName?: string) {
  const requestOption = {
    method: "POST",
    headers: authHeader(),
    body: JSON.stringify({
      email: mobileNo.email,
      phone:  mobileNo.number,
      app: 1,
      appDeviceOs: 2,
      otpCode: mobileNo.otpNumber,
      appDeviceUniqueId: "webapp",
      name: mobileNo.fName ? mobileNo.fName + " " + mobileNo.lName : oldName,
    }),
  };

  return fetch(apiurl + "api/otp-login", requestOption)
    .then(handleResponse)
    .then((data) => {
      if (data === "SMS Code does not match or has expired") {
        return "SMS Code does not match or has expired";
      } 
      else if(data === "Magic Link does not match or has expired"){
        return "Magic Link does not match or has expired";
      }
      else {
        if(data.data != null){
          localStorage.setItem("BearerToken", JSON.stringify(data.data.id_token));
          localStorage.setItem("userDetails", JSON.stringify(data.data.user));
          localStorage.setItem("IsUserReloaded", "true");
        }
        return data;
      }
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get user details of eautorescue
function getUserDetailsOfEautoReacue(
  data: string
) {
  const requestOption = {
    method: "GET",
    headers: header(),
    // body: JSON.stringify({
    //   data: data
    // }),
  };

  return fetch(apiurl + "api/navigate?data=" + data, requestOption)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: null,
        error: error?.detail || "Failed to fetch",
      };
    });
}

// check user is exist or not from email
function savePrimaryEmail(email) {
  const requestOption = {
    method: "POST",
    headers: authHeader()
  };

  return fetch(apiurl + `api/primary-email?email=${email}`, requestOption)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: null,
        error: error?.detail || "Failed to fetch",
      };
    });
}