import React from 'react';

import { actionTypes } from 'constants/global/action.type';
import { globalServices } from 'services/global/global.services';

export const globalActions = {
    isShowHeaderFooter,
    fetchEnterpriseSetting,
    fetchCustomMessageJSONData,
    isCallFromExpress,
    getExtendNotificationDetail
};

// set ShowHeaderFooter 
function isShowHeaderFooter(isShowHF) {
    return (dispatch: any) => {
        dispatch({ type: actionTypes.FETCH_IS_SHOW_HEADER_FOOTER,isShowHF });
      };
}

// get enterprise setting details - read it from config.js
function fetchEnterpriseSetting(enterpriseSetting : any) {
    return (dispatch: any) => {
        dispatch({ type: actionTypes.FETCH_ENTERPRISE_SETTINGS,enterpriseSetting });
      };
}

// get json data from enterprise setting details
function fetchCustomMessageJSONData(fetchCustomMessageJSONData : any) {
    return (dispatch: any) => {
        dispatch({ type: actionTypes.FETCH_CUSTOM_MESSAGE_JSON_DATA,fetchCustomMessageJSONData });
      };
}

// set whether is call from express or not
function isCallFromExpress(callFromExpress : boolean){
    return(dispatch: any) => {
        dispatch({type:actionTypes.IS_CALL_FROM_EXPRESS, callFromExpress})
    }
}

// set for sub portal navigation api data
function fetchNavigateData(){
    return (dispatch: any) => {
        dispatch(updateData([]));
        globalServices.fetchNavigateData().then(
          (data: any) => {
            dispatch(updateData(data));
          },
          (error: any) => {
            dispatch(updateData([]));
          }
        );
      };
    
      function updateData(navigateDetail: any) {
        return { type: actionTypes.FETCH_NAVIGATE_DATA, navigateDetail };
      }
}

// get extend notification detail
function getExtendNotificationDetail () {
  return (dispatch: any) => {
    dispatch(updateData([]));
    globalServices.getExtendNotificationDetail().then(
      (data: any) => {
        dispatch(updateData(data?.data));
      },
      (error: any) => {
        dispatch(updateData([]));
      }
    );
  };

  function updateData(extendNotificationDetail: any) {
    return { type: actionTypes.FETCH_EXTEND_NOTIFICATION_DETAIL, extendNotificationDetail };
  }
}