import { combineReducers } from 'redux';
import { home } from 'reducers/home/home.reducers';
import { login } from 'reducers/login/login.reducers';
import { wallet } from 'reducers/wallet/wallet.reducers';
import { profile } from 'reducers/profile/profile.reducers'
import { global } from 'reducers/global/global.reducers';
import { expressOrder } from './expressOrder/expressOrder.reducers';
import { passes } from 'reducers/passes/passes.reducers';

const rootReducer = combineReducers({
  home,
  login,
  wallet,
  profile,
  global,
  expressOrder,
  passes
});

export default rootReducer;
