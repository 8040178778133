import { actionTypes } from "constants/global/action.type";
import { loginConstants } from "constants/login/login.constant";
import navigationService from "helpers/history";
import {store} from "../store/store"

//get decoded jwt
const parseJWT = (token) => {
  try{
    return JSON.parse(atob(token.split('.')[1]));
  }
  catch (e){
    console.log(e);
    var splitToken = token.split('.')[1];
    var parseToken = JSON.parse(atob(splitToken.replace(/-/g, '+').replace(/_/g, '/')));
    return parseToken;
  }
}

// get token from storage
export const getTokenFromStorage = () => {
    const token = localStorage.getItem("BearerToken") != null ?JSON.parse(localStorage.getItem("BearerToken") || "") : null;
    if(token){
      const decodedJwt = parseJWT(token);
      if(decodedJwt.exp * 1000 < Date.now()){
        store.dispatch({ type: loginConstants.USER__DETAIL, userDetail: null });
        localStorage.removeItem("BearerToken");
        localStorage.removeItem("userDetails");
        store.dispatch({ type: loginConstants.SET_USER_LOGOUT, isUserLogOut: true });
        // setTimeout(() => {
        //   navigationService?.navigation("/");
        //   window.history.replaceState(null, "", "/");
        //   store.dispatch({ type: actionTypes.SET_BASENAME, baseName: "/" })
        // }, 0)
      }
      else{
        return token;
      }
    }
    else {
        localStorage.removeItem("BearerToken");
        localStorage.removeItem("userDetails");
    }
  }

