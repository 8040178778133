import { f7ready } from "framework7-react";
import navigationService from "./history";
import {store} from "../store/store"
import { loginConstants } from "constants/login/login.constant";

// handle api response
export function handleResponse(response: any) {
  let error = "";
  return response.clone().json().then(
    (data: any) => {
      if (!response.ok) {
        error = (data && data.detail) || response.statusText;
        return Promise.reject(data);
      }
      return {
        status: response.status,
        data,
        error: error,
      };
    },
    async (error: any) => {
      if (!response.ok) {
        // if (response.status === 401) {
        //   navigationService.navigation("/");
        //   localStorage.removeItem("BearerToken");
        //   localStorage.removeItem("userDetails");
        //   store.dispatch({ type: loginConstants.USER__DETAIL, userDetail: null });
        //   store.dispatch({ type: loginConstants.SET_USER_LOGOUT, isUserLogOut: true });
        //   return null;
        // }
        let errorMess = await response.text();
        if(response.status === 403){
          return {
            status: response.status,
            error: errorMess,
          }
        }
        let mainApiErrorModal = document.querySelector('.api-error-model') as any;
        let errorModalBodyMessage = mainApiErrorModal.querySelector('#errorMessage').getElementsByTagName("span") as any;
        if (errorModalBodyMessage) {
          if (errorMess) {
            errorModalBodyMessage[0].innerHTML = errorMess;
          }
        }
        if (errorMess === "Scope is not valid please pass the proper enterprise Name" || errorMess === "Scope is not valid please pass the proper Scope Name") {
          navigationService.navigation("/navigationerror");
        }
        else if(errorMess?.toLowerCase() === "you are not access to this scope."){
          navigationService.navigation("/AccessDenied");
        }
        else {
          if(errorMess){
            if(errorMess !== "SMS Code does not match or has expired" && errorMess !== "Magic Link does not match or has expired"){
              f7ready((f7) => {
                setTimeout(() => {
                  let anyPoup = f7.popup.get('.modal-in');
                  anyPoup?.close();
                  let popup = f7.popup.get('.api-error-model');
                   popup.open();
                }, 0);
              });
            }
            else {
              return errorMess;
            }
          }
          if (errorMess?.toLowerCase()?.includes('invalid email addresses')) {
            return {
              status: response.status,
              error: errorMess,
            }
          }
        }
        // return response.text()
      // .then((json : any) => { 
      //    return {
      //     status: response.status,
      //     error: json,
      //   };
      //  })
      // .catch(e => {console.log("error", e)})
      }
    }
  );
}
