import React from 'react';

const Yloader: React.FC = () => {
    return (
        <>
            <section className='bg-white'>
                <div className='container no-padding'>
                    <div className='max-height main-Loader'>
                        <div className="spinner"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Yloader;