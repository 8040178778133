import React from 'react';
import { Link } from 'framework7-react';

interface IlinkProps {
  className?: any; // set class which is conditional
  children?: any; // set any control which is conditional
  popupOpen?: string; // set property which is conditional
  popupClose?: any; // set property which is conditional
  href?: any; // set href which is conditional
  onclickEvent? : (arg: any,id?:string) => void; // set onclick which is conditional
  style? : any; // set style of link which is conditional
  tabLink?: string; // set tablink from profile menubar
  tabLinkActive?: any; // set Active tab link from profile menubar
  text?: string; // set text for link
  target?: any;
  id?: any
}

const Ylink: React.FC<IlinkProps> = ({
  className,
  popupOpen,
  children,
  popupClose,
  href,
  onclickEvent,
  style,
  tabLink,
  tabLinkActive,
  text,
  target,
  id
}) => {
  return (
    <Link
      className={className}
      popupOpen={popupOpen}
      popupClose={popupClose}
      href={href}
      onClick={onclickEvent}
      style={style}
      tabLink={tabLink}
      tabLinkActive={tabLinkActive}
      text={text}
      target={target}
      id={id}
    >
      {children}
    </Link>
  );
};

export default Ylink;
