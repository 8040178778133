import React, { useEffect } from "react";
import { Button } from "framework7-react";

interface IbuttonProps {
  btnText?: string; // set button text which is conditional
  btnId?: any; // set button id which is conditional
  btnClass?: any; // set button class
  children?: any; // set any control which is conditional
  popupOpen?: string; // set for open popup which is conditional
  popupClose? : boolean; // set for close popup which is conditional
  handleChangeValue?: (any) ; // set onclick event
  outline?: boolean; // button property which is conditional
  round?: boolean; // button property which is conditional
  disabled?:boolean; // button property to disable it
  color?:string; // button property to display color
  isFill?:boolean; //check when true then fill attribute for button otherwise not supported
  style?:any;//set style which apply dynamic
  modelName?: any;
  modelId?:string;
}

const Ybutton: React.FC<IbuttonProps> = ({
  children,
  btnText,
  btnId,
  btnClass,
  handleChangeValue,
  outline,
  popupClose,
  round,
  disabled,
  color,
  popupOpen,
  isFill,
  style,
  modelName,
  modelId
}) => {

  useEffect(() => {
    if (btnId) {
      let lastElement = document.getElementById(btnId) as any;
      if (lastElement) {
        if (lastElement.addEventListener) {
          lastElement.addEventListener('keydown', onKeyDownOpenPopup, false);
        }
      }
    }
  }, [btnId])

  const onKeyDownOpenPopup = (e) => {
    if (e.keyCode === 13) {
      let mainModel;
      if (modelId) {
        mainModel = document.querySelector(`#${modelId}`) as any
      }
      else {
        mainModel = document.querySelector(`.${modelName}`) as any
      }
      let focusedElement = mainModel?.getElementsByTagName("a") as any;
      if (focusedElement) {
        setTimeout(() => {
          focusedElement[0]?.focus();
        }, 500);
      }
    }
    if (e.keyCode === 9) {
      if (btnId === "Get-Report") {
        let mainModel = document.getElementsByClassName("themeFooter") as any;
        if (mainModel) {
          let focusedElement = mainModel[0]?.getElementsByClassName("smallText") as any;
          if (focusedElement) {
            setTimeout(() => {
              focusedElement[0]?.focus();
            }, 0);
          }
        }
      } else if(btnId === "addEmailNextBtn") {
        let backBtnDiv = document.querySelector('div.backBtn[id="General"]')?.querySelector('a.btn-green') as any;
        if (backBtnDiv) {
          setTimeout(() => {
            backBtnDiv.setAttribute("tabIndex", "0");
            backBtnDiv?.focus();
          }, 0);
        }
      }
    }
  }

  return (
    isFill ? (<Button id={btnId} fill color={color} outline={outline} round={round} popupClose={popupClose} popupOpen={popupOpen} className={btnClass} onClick={handleChangeValue} disabled={disabled} style={style}>
      {btnText}
      {children}
    </Button>) : (<Button id={btnId} type="text" className={btnClass ? "themeBtn " + btnClass : "themeBtn"} color={color} outline={outline} round={round} popupClose={popupClose} popupOpen={popupOpen} onClick={handleChangeValue} disabled={disabled} style={style}>
      {btnText}
      {children}
    </Button>)

  );
};

export default Ybutton;
