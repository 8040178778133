export const actionTypes={
    FETCH_IS_SHOW_HEADER_FOOTER: 'FETCH_IS_SHOW_HEADER_FOOTER',
    FETCH_ENTERPRISE_SETTINGS: 'FETCH_ENTERPRISE_SETTINGS',
    FETCH_CUSTOM_MESSAGE_JSON_DATA: 'FETCH_CUSTOM_MESSAGE_JSON_DATA',
    IS_CALL_FROM_EXPRESS: "IS_CALL_FROM_EXPRESS",
    IS_TOKEN_EXPIRED: "IS_TOKEN_EXPIRED",
    FETCH_NAVIGATE_DATA: "FETCH_NAVIGATE_DATA",
    SET_BASENAME: "SET_BASENAME",
    IS_ENTERPRISE_LISTING_PAGE: "IS_ENTERPRISE_LISTING_PAGE", 
    IS_CALL_FROM_BROWSER_BACK_EVENT: "IS_CALL_FROM_BROWSER_BACK_EVENT",
    SET_CURRENT_LOCATION: "SET_CURRENT_LOCATION",
    FETCH_EXTEND_NOTIFICATION_DETAIL: "FETCH_EXTEND_NOTIFICATION_DETAIL",
    SET_REFRESH_RECAPTCHA: "SET_REFRESH_RECAPTCHA",
    SET_RECAPTCHA_TOKEN: "SET_RECAPTCHA_TOKEN"
}