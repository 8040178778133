import { Popup, Row, Col } from "framework7-react";
import Ybutton from "controls/Ybutton";
import Ylink from "controls/Ylink";
import Yicon from "controls/Yicon";
import { useEffect } from "react";

interface IerrorModal {
    popupHeaderText?: string;
    popupBodyText?: any;
    isShowFooter?: boolean;
    btnOnclick?: any;
    btnText? : string;
    linkText?: any;
    closeLinkText?: string;
    linkOnClick?: any;
    isOpened?: boolean
    id?: any
    focusElementId?: string // When we close Popup by Pressing Enter Key then Set Focus Element After Close Popup
}

const YapiErroModal: React.FC<IerrorModal> = ({ popupHeaderText, popupBodyText, isShowFooter, btnOnclick, btnText, linkText, closeLinkText, linkOnClick,isOpened, id, focusElementId }) => {
  
    const setTabIndex = () => {
        setTimeout(() => {
            let mainDiv = document.querySelector(`#apiErrorModal`) as any;
            if (mainDiv) {
                let headerElement = mainDiv?.querySelector(".heading");
                headerElement?.focus();
                mainDiv.setAttribute("tabIndex", "0");
                let elementArray = mainDiv.getElementsByTagName("a") as any;
                let lastElement = elementArray[elementArray.length - 1];
                if (lastElement.addEventListener) {
                    lastElement.addEventListener('keydown', setFocusOnTab, false);
                }
            }
        }, 500)
    }

    const setFocusOnTab = (e) => {
        if (e.keyCode === 9) {
            let mainModel = document.querySelector(`#apiErrorModal`) as any;
            mainModel.focus();
        }
        if (e.keyCode === 13) {
            let blockedTitle = document.querySelector(`#${focusElementId}`) as any;
            if (blockedTitle) {
                setTimeout(() => {
                    blockedTitle.focus();
                }, 500);    
            }
        }
    }

    return (
        <>
        <Popup closeByBackdropClick={false} className="invaild-alert-Model message-alert-Model api-error-model" id="apiErrorModal" onPopupOpen={setTabIndex}>
            <div className="display-flex justify-content-space-between">
                <Ylink className="linkMain justify-content-flex-end" popupClose>
                  Close<Yicon text="xmark"></Yicon>
                </Ylink>
              </div>

                <div className="cardHeader">
                    <h2 className="heading" tabIndex={0}>Error</h2>
                </div>
                <div className="card-body">
                    <p className="SmallText" id="errorMessage">
                        <span tabIndex={0}></span>
                    </p>
                </div>
                <div className="cardfooter">
                    <div className="text-center">
                        <Row className="width-100">
                            <Col width="100" className="text-center">
                                <Ybutton popupClose>
                                    OK
                                </Ybutton>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Popup>
        </>
    )
}

export default YapiErroModal;