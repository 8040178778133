import { authHeader, header } from "helpers/header";
import { handleResponse } from "helpers/handleResponse";
import { error } from "console";
import { store } from "../../store/store"
const apiurl = process.env.REACT_APP_API_URL;

export const globalServices = {
    isShowHeaderFooter,
    fetchNavigateData,
    fetchTextJsonData,
    getExtendNotificationDetail
};

// get place detail
function isShowHeaderFooter() {
  const requestOption = {
    method: "GET",
    headers: header(),
  };

  return fetch(apiurl + "api/places", requestOption)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// fetch navigate api data 
function fetchNavigateData(){
   // checking scopename, placname using instancename temporary 
   //TODO:- folder wise create dynamic enterprise 30-1-2023
  let locationPathName = decodeURI(window.location.pathname.toLowerCase());
  const requestOption = {
    method: "POST",
    headers: header(),
    body: JSON.stringify({
     navigationLevel: 0,
      geoLocation: {
        latitude: 0,
        longitude: 0
      },
      data: "",
      scopeName: locationPathName.split("/")[1],
      placeName: locationPathName.split("/").length > 1 ? locationPathName.split("/")[2] : ""
     // scopeName: locationPathName.includes(data.instanceName) ? data.instanceName.split("/")[1] : "",
     // placeName: locationPathName.includes(data.instanceName) ? locationPathName.split("/").length > 0 ? decodeURI(locationPathName.split("/")[2]) : "" : locationPathName.split("/").length > 0 ? decodeURI(locationPathName.split("/")[1]) : ""
    }),
  };

  return fetch(apiurl + `api/navigate-portal`, requestOption)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get dynamic text json data 
function fetchTextJsonData (enterpriseName: string){
  const requestOption = {
    method: "GET",
    headers: header()
  };

  return fetch(apiurl + `api/pagebuilder?enterpriseName=${enterpriseName}`, requestOption)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get extend notification detail
function getExtendNotificationDetail(){
  const requestOption = {
    method: 'GET',
    headers: authHeader(),
  };
  
  // use replace all beacause of some enterprise name contains - and api throw error when pass this type of enterprisename
  return fetch(`${apiurl}api/wallet-extend-notification`, requestOption)
    .then(handleResponse)
    .then((data) => {
      
      return {
        status: "success",
        data: data.data,
        error: '',
      };
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || 'Failed to fetch',
      };
      
    });
}
