import { walletConstants } from "constants/wallet/action.type";

const initialState = {
  wallet: [],
  walletDetail: null,
  scan:{},
  twoFatore:{},
  isCallFromWalletDetail: false,
  transferWalletDetail: null,
  offlineWallet: [],
  userPortfolioDetail: [],
  driverId: null,
  redeemPlaceId: null
};

export function wallet(state = initialState, action: any) {
  switch (action.type) {
    case walletConstants.FETCH_WALLET: // wallet
      return {
        ...state,
        wallet: action.walletDetail,
      };
    case walletConstants.FETCH_WALLET_DETAILS: // wallet deails
      return {
        ...state,
        walletDetail: action.walletDetails,
      };
      case walletConstants.FETCH_SCAN_WALLET: // wallet deails
      return {
        ...state,
        scan: action.walletScan,
      };
      case walletConstants.FETCH_TWO_FACTOR_WALLET: // two factor for scan
      return {
        ...state,
        twoFatore: action.twoFatore,
      };
      case walletConstants.IS_CALL_FROM_WALLET_DETAIL: // set true or false
        return{
          ...state,
          isCallFromWalletDetail: action.isCallFromWalletDetail
        }
      case walletConstants.GET_TRANSFER_WALLET_DETAIL: // transfer pass detail
        return{
          ...state,
          transferWalletDetail : action.transferWalletDetail
        }
    case walletConstants.FETCH_OFFLINE_WALLET: // transfer pass detail
      return {
        ...state,
        offlineWallet: action.offlineWalletDetail
      }
    case walletConstants.GET_USER_PORTFOLIO: // user portfolio detail
      return{
        ...state,
        userPortfolioDetail: action.userPortfolioDetail
      }
    case walletConstants.DRIVER_ID: // driver id - externalbridge - navigate API
      return{
        ...state,
        driverId: action.driverId
      }
    case walletConstants.REDEEM_PLACE_ID: // place id - externalbridge - navigate API
      return{
        ...state,
        redeemPlaceId: action.redeemPlaceId
      }
    default:
      return state;
  }
}
