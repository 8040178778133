import { getTokenFromStorage } from "helpers/authentication";
import { handleResponse } from "helpers/handleResponse";
import { authHeader, captchaAuthHeader, enigmaHeader, header } from "helpers/header";
import { store } from "../../store/store"
const apiurl = process.env.REACT_APP_API_URL;

export const passesServices = {
    getGeneralSetting,
    getPlaceDetail,
    getPlaceDetailBasedOnPlaceId,
    getPlaceNameFromSearch,
    getEnigmaFileUrl,
    uploadFileToEnigma,
    saveApplicationDocuments,
    getPlaceDetailBasedOnFilterizeValue,
    getCartDetail,
    addToItemCart,
    deleteItemById,
    getApplicationDetailBasedOnCatalogItem,
    createOrder,
    deleteCart,
    addPlaceToFavorite,
    removePlaceToFavorite,
    getFavoritePlaceDetail,
    getValidateSellCardMessage,
    getUserAddressDetail,
    saveUserNewAddressDetail,
    getPriceDetailBasedOnQuantity,
    getEnigmaCardVaultUrl,
    saveEnigmaCardVault,
    getEnigmaCvvUrl
}

// get general setting based on scope name
function getGeneralSetting() {
    let enterprise = store.getState().global;
    let enterpriseName = enterprise?.baseName === "/" ? "yodel" : enterprise?.baseName?.split("/")[1];
    const requestOption = {
      method: "GET",
      headers: header(),
    };
  
    return fetch(apiurl + `api/general-setting-portal-filters?enterpriseName=${enterpriseName}`, requestOption)
      .then(handleResponse)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return {
          status: error?.status,
          data: null,
          error: error?.message || "Failed to fetch",
        };
      });
}

// get place detail based on use logout or login
function getPlaceDetail(userDetail, currentLocation, pageNumber) {
  let enterprise = store.getState().global;
  const requestOption = {
    method: "GET",
    headers: userDetail ? authHeader() : header(),
  };

  return fetch(apiurl + `api/places?enterpriseName=${enterprise?.baseName?.split("/")[1].replace(/-/g, " ")}&loc=${currentLocation?.latitude},${currentLocation?.longitude}&availableOnChannel=2&pageNumber=${pageNumber}`, requestOption)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get place detail based on place id
function getPlaceDetailBasedOnPlaceId (placeId : any) {
  const requestOptions = {
    method: "GET",
    headers: header(),
  };

  return fetch(
    apiurl + `api/places/${placeId}`,
    requestOptions
  )
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || 'Failed to fetch',
      };
    });
}

// get Place name from filter seacrh
function getPlaceNameFromSearch(searchText, useDetail?, currentLocation?: any){
  let baseNameFromStore = store.getState().global;
  const requestOption = {
    method: "GET",
    headers: useDetail ?  authHeader() : header(),
  };

  return fetch(apiurl + `api/places?q=${searchText}&enterpriseName=${baseNameFromStore?.baseName?.split("/")[1].replace(/-/g, " ")}&availableOnChannel=2&loc=${currentLocation?.latitude},${currentLocation?.longitude}`, requestOption)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
  
}

// get enigma file url for file upload
function getEnigmaFileUrl(fileRequestData, fileExtension) {
  const requestOptions = {
    method: "GET",
    headers: enigmaHeader(),
  };

  return fetch(
    apiurl + `api/enigma/filevault/uploadurl?contentType=${fileRequestData.fileType}&fileExtension=${fileRequestData.fileExtension}`,requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || 'Failed to fetch',
      };
    });
}

// upload selected document to enigma
function uploadFileToEnigma(url, fileType, fileContent) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': fileType.fileType },
    body: fileContent
  };

    return fetch(url, requestOptions)
      .then(handleResponse)
      .then((data) => {
        if(data.status === 403){
          return data;
        }
        else{
          return true;
        }
      })
      .catch((error) => {
        return {
          status: error?.status,
          data: {},
          error: error?.message || 'Failed to fetch',
        };
      });
}

// save application document
function saveApplicationDocuments(applicationDocumnetRequest) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      "id": applicationDocumnetRequest.id,
      "userId":applicationDocumnetRequest.userId,
      "applicationId":applicationDocumnetRequest.applicationId,
      "status": applicationDocumnetRequest.status,
      "UserApplicationDocumentList": applicationDocumnetRequest.customerApplicationDocuments,
      "scopeId": applicationDocumnetRequest.scopeId,
      "catalogItemId": applicationDocumnetRequest.catalogItemId,
      "vehicleLicensePlate": applicationDocumnetRequest.vehicleLicencePlate,
      "vehicleLicensePlateState": applicationDocumnetRequest.vehcileState
    })
  };

  return fetch(apiurl + `api/application-document`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get place detail based on filterize value
function getPlaceDetailBasedOnFilterizeValue (filterizePlaceRequestDetail) {
  const requestOptions = {
    method: 'POST',
    headers: header(),
    // body: JSON.stringify({
    //   userId: filterizePlaceRequestDetail.userId,
    //   placeId: filterizePlaceRequestDetail.placeId,
    //   reservationDate: filterizePlaceRequestDetail.reservationDate,
    //   catalogItemType: filterizePlaceRequestDetail.catalogItemType,
    //   scopeName: filterizePlaceRequestDetail.scopeName.replace(/-/g, " ")
    // }),
    body: JSON.stringify(filterizePlaceRequestDetail),
  };

  return fetch(apiurl + "api/portal-catalog-items", requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get cart detail
function getCartDetail() {
  const requestOption = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(apiurl + `api/cart`, requestOption)
    .then(handleResponse)
    .then((data) => {
      // localStorage.setItem("cartDetail", "");
      // localStorage.setItem("cartDetail", JSON.stringify(data.data))
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// add item into cart
function addToItemCart(cartRequestParam, isEnableCaptcha) {
  const requestOption = {
    method: "PUT",
    headers: isEnableCaptcha ? captchaAuthHeader() : authHeader(),
    body: JSON.stringify(cartRequestParam)
  };

  return fetch(apiurl + `api/cart`, requestOption)
    .then(handleResponse)
    .then((data) => {
      // localStorage.setItem("cartDetail", "");
      // localStorage.setItem("cartDetail", JSON.stringify(data.data))
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// delete cart item by id
function deleteItemById(cartId) {
  const requestOption = {
    method: "DELETE",
    headers: authHeader(),
  };

  return fetch(apiurl + `api/cart/${cartId}`, requestOption)
    .then(handleResponse)
    .then((data) => {
      return true;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get application detail based on catalogItemId
function getApplicationDetailBasedOnCatalogItem(detailRequest) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/get-application-details?catalogItemID=${detailRequest.catalogItemId}&sourceScopeId=${detailRequest.scopeId}&userId=${detailRequest.userId}&vehicleState=${detailRequest.vehicleState}
    &placeId=${detailRequest.placeId}&quantity=${detailRequest.qty}&effectiveDateLtc=${detailRequest.effectiveDate}&vehicleLicensePlate=${detailRequest.vehicleLicensePlate}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

function createOrder (paymentCardId, squarePaymentToken) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({
      "paymentCartId" : Number(paymentCardId),
      "squarePaymentToken" : squarePaymentToken
    })
  };

  return fetch(apiurl + `api/orders/checkout`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// delete cart
function deleteCart () {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/cart`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// add place to favorite
function addPlaceToFavorite(placeId) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/user-favorite-place/${placeId}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return true;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// remove place from favorite
function removePlaceToFavorite(placeId) {
  const requestOptions = {
    method: 'DELETE',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/user-favorite-place/${placeId}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get favorite place
function getFavoritePlaceDetail(currentLocation) {
  let enterprise = store.getState().global;
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/user-favorite-place?enterpriseName=${enterprise?.baseName?.split("/")[1].replace(/-/g, " ")}&loc=${currentLocation?.latitude},${currentLocation?.longitude}&availableOnChannel=2`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get checkout sell card message
function getValidateSellCardMessage(requestParam){
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body:JSON.stringify({
      "scopeID" : requestParam.scopeId,
      "catalogItem": requestParam.catalogItemId,
      "data": requestParam.paymentCardNumber,
      "cardType": requestParam.cardType
    })
  };

  return fetch(apiurl + `api/validate-sell`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

function getUserAddressDetail () {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/user-address`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data.data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

function saveUserNewAddressDetail (requestParam) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body:JSON.stringify({
      "id": 0,
      "userId" : 0,
      "firstName": requestParam.firstName,
      "lastName": requestParam.lastName,
      "address1": requestParam.address1,
      "address2": requestParam.address2,
      "city": requestParam.city,
      "state": requestParam.state,
      "country": requestParam.country,
      "postalCode": requestParam.zipcode,
    })
  };

  return fetch(apiurl + `api/user-address`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

// get price summary based on quantity increase decrease
function getPriceDetailBasedOnQuantity (catalogItemId : number,placeId : number, qty: number, ltcDate, licensePlate?: "", licensePlateState?: "", extendPassDuration?: number) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/catalog-items/${catalogItemId}?licensePlate=${licensePlate ? licensePlate : ""}&licensePlateState=${licensePlateState ? licensePlateState : ""}&extendPassDuration=${extendPassDuration ? extendPassDuration : 0}&placeId=${placeId}&quantity=${qty}&ltc=${ltcDate}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: error?.detail,
        error: error?.message || "Failed to fetch",
      };
    });
}

// get enigma card vault url
function getEnigmaCardVaultUrl() {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiurl + 'api/enigma-store-card', requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: error?.detail,
        error: error?.message || "Failed to fetch",
      };
    });
}

// save enigma card vault
function saveEnigmaCardVault(cardInfo) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body:JSON.stringify(cardInfo)
  };

  return fetch(apiurl + `api/enigma-store-card`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: {},
        error: error?.message || "Failed to fetch",
      };
    });
}

function getEnigmaCvvUrl(paymentCardId) {
  const requestOptions = {
    method: 'GET',
    headers: authHeader(),
  };

  return fetch(apiurl + `api/enigma-store-card-cvv/${paymentCardId}`, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return {
        status: error?.status,
        data: error?.detail,
        error: error?.message || "Failed to fetch",
      };
    });
}