import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './store/store';
// import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react'
import Framework7 from 'framework7/lite-bundle';
import Framework7React from 'framework7-react';
Framework7.use(Framework7React);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// remove loader of index.html page
let indexLoader = document.getElementsByClassName("index-bg-white")[0];
indexLoader?.remove();

root.render(
  <Provider store={store}>
    <App />
    {/* <FpjsProvider
      loadOptions={{
        apiKey: "nqYTuI41ClGc17dU3ANq"
      }}>
    <App />
    </FpjsProvider> */}
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
