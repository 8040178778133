import { actionTypes } from "constants/global/action.type";

const initialState = {
  isShowHeaderFooter:false, //show header footer based on condition
  enterpriseSetting: null,
  fetchCustomMessageJSONData: null,
  isCallFromExpress: false,
  navigateDetail: null,
  baseName: "",
  isEnterPriseListingPage: true,
  isCallFromBrowserBackBtn: false,
  isShowSectionBanner: true,
  currentLocation: null,
  extendNotificationDetail: [],
  reCaptchaToken:"",
  isRefreshReCaptcha : false
};

export function global(state = initialState, action: any) {
  switch (action.type) {
      case actionTypes.FETCH_IS_SHOW_HEADER_FOOTER: // 
      return {
        ...state,
        isShowHeaderFooter: action.isShowHF,
      };
      case actionTypes.FETCH_ENTERPRISE_SETTINGS: // 
      return {
        ...state,
        enterpriseSetting: action.enterpriseSetting,
      };
      case actionTypes.FETCH_CUSTOM_MESSAGE_JSON_DATA: // 
      return {
        ...state,
        fetchCustomMessageJSONData: action.fetchCustomMessageJSONData,
      };
      case actionTypes.IS_CALL_FROM_EXPRESS: // set is call from express order or not
      return{
        ...state,
        isCallFromExpress: action.callFromExpress
      };
      case actionTypes.FETCH_NAVIGATE_DATA: // fetch navigate data
      return{
        ...state,
        navigateDetail: action.navigateDetail
      };
      case actionTypes.SET_BASENAME: // set basename
      return{
        ...state,
        baseName: action.baseName
      };
      case actionTypes.IS_ENTERPRISE_LISTING_PAGE:
      return{
        ...state,
        isEnterPriseListingPage: action.isEnterPriseListingPage
      };
      case actionTypes.IS_CALL_FROM_BROWSER_BACK_EVENT: // set is call from browser back button or not
        return{
          ...state,
          isCallFromBrowserBackBtn: action.isCallFromBrowserBackBtn
        }
      case actionTypes.SET_CURRENT_LOCATION: // set current location
        return{
          ...state,
          currentLocation: action.currentLocation
        }
      case actionTypes.FETCH_EXTEND_NOTIFICATION_DETAIL: // set current location
        return{
          ...state,
          extendNotificationDetail: action.extendNotificationDetail
        }
      case actionTypes.SET_REFRESH_RECAPTCHA: // set current location
        return{
          ...state,
          isRefreshReCaptcha: action.isRefreshReCaptcha
        }
      case actionTypes.SET_RECAPTCHA_TOKEN: // set current location
        return{
          ...state,
          reCaptchaToken: action.reCaptchaToken
        }
    default:
      return state;
  }
}
