import { passesConstants } from "constants/passes/passes.constant";

const initialState = {
    generalSettingDetail: null,
    placeDetail: null,
    cartDetail: null,
    orderDetail: null,
    isCartDeleted: false,
    IsPageReload: false
}

export function passes(state = initialState, action: any) {
    switch (action.type) {
        case passesConstants.GET_GENERAL_SETTINGS: // general setting
            return {
                ...state,
                generalSettingDetail: action.settingDetail
            }
        case passesConstants.GET_PLACE_DETAIL_BASED_ON_ID: // place detail based on placeid
            return {
                ...state,
                placeDetail: action.placeDetail
            }
        case passesConstants.GET_CART_DETAIL: // get cart detail
            return {
                ...state,
                cartDetail: action.cartDetail
            }
        case passesConstants.GET_CREATED_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: action.orderDetail
            }
        case passesConstants.IS_CART_DELETED:
            return{
                ...state,
                isCartDeleted: action.isCartDeleted
            }
        case passesConstants.RELOAD_PAGE:
            return {
                ...state,
                IsPageReload: action.IsPageReload
            }
        default:
            return state;
    }

}   