export const walletConstants = {
  FETCH_WALLET: 'FETCH_WALLET',
  FETCH_WALLET_DETAILS: 'FETCH_WALLET_DETAILS',
  FETCH_SCAN_WALLET:'FETCH_SCAN_WALLET',
  FETCH_TWO_FACTOR_WALLET:'FETCH_TWO_FACTOR_WALLET',
  IS_CALL_FROM_WALLET_DETAIL: "IS_CALL_FROM_WALLET_DETAIL",
  GET_TRANSFER_WALLET_DETAIL: "GET_TRANSFER_WALLET_DETAIL",
  FETCH_OFFLINE_WALLET: "FETCH_OFFLINE_WALLET",
  GET_USER_PORTFOLIO: "GET_USER_PORTFOLIO",
  DRIVER_ID: "DRIVER_ID", // for save driver id which is return from userdetail - navigate API in external bridge
  REDEEM_PLACE_ID: "REDEEM_PLACE_ID" // for save place id which is return data scanning from clover - navigate API in external bridge
};
