import { ProfileConstants } from "constants/profile/profile.constant";

const initialState = {
    vehicleDetail :[],
    newVehicle: false,
    newDriver: false
};

export function profile(state = initialState, action: any) {
  switch (action.type) {
    case ProfileConstants.GET_VEHICLE_LIST: // vehicle details
      return {
        ...state,
        vehicleDetail: action.vehicleDetail,
      };
      case ProfileConstants.ADD_NEW_VEHICLE: // add vehicle 
      return {
        ...state,
        newVehicle: action.addNewVehicle,
      };
      case ProfileConstants.ADD_NEW_DRIVER: // add driver
      return {
        ...state,
        newDriver: action.addNewDriver,
      };
    default:
      return state;
  }
}