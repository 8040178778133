import { loginConstants } from "constants/login/login.constant";

const initialState = {
  emailVerified: "",
  generateOtp: "",
  verification: null,
  userDetail:"",
  isUserLogOut: false
};

export function login(state = initialState, action: any) {
  switch (action.type) {
    case loginConstants.GET_EMAIL_VERIFIED: // email verifiaction
      return {
        ...state,
        emailVerified: action.emailVerified,
      };
    case loginConstants.GET_OTP_dETAIL: // generate otp
      return {
        ...state,
        generateOtp: action.generateOtp,
      };
    case loginConstants.GET_OTP_VERIFIED: // otp verify
      return {
        ...state,
        verification: action.verifiedOtpDetail,
      };
    case loginConstants.USER__DETAIL: // user detail
      return {
        ...state,
        userDetail: action.userDetail,
        emailVerified: null,
      };
    case loginConstants.SET_USER_LOGOUT: // user logout
      return{
        ...state,
        isUserLogOut: action.isUserLogOut
      }
    default:
      return state;
  }
}
